import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearClientDetails, Tasks_GetUserTask_Progress } from '../../../Services/Actions';
import history from '../../../history';

import * as styles from './styles';

import moment from 'moment';

import { Icon as IconComponent } from '../../../Components/IconLibrary';
import * as IconsList from '../../../Components/IconLibrary';
import Loading from '../../../Components/Loading';
import Page from '../../../Components/Page';
import PageText from '../../../Components/Text/PageText';
import Spacer from '../../../Components/Spacer';

// import CheckmarkSelected from '../../../Assets/Icons/Checkmark_Selected.png';
// import DeleteX from '../../../Assets/Icons/Delete_X.png';
// import Pending from '../../../Assets/Icons/Pending.png';

class TaskProgressComponent extends React.PureComponent {
    _isMounted = false;

    state = {
        FirstLoad: false,
        Calendar: {
            DayNames: [],
            Years: []
        },
        Stats: {
            DayNames: [],
            Years: [],
        },
        Task: {}
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId, TaskId } = this.props.match.params;

        if (!+ClientId || !+TaskId) history.push('/');
        else this.onGetProgress();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearClientDetails();
    }

    onGetProgress = () => {
        var { ClientId: UserId, TaskId } = this.props.match.params;

        this.props.Tasks_GetUserTask_Progress({ TaskId, UserId }).then(({ Calendar, Stats, Task }) => {
            if (!this.state.FirstLoad) this.setState({ Calendar, FirstLoad: true, Stats, Task });
            else this.setState({ Calendar, Stats, Task });
        })
    }

    renderCalendar = () => {
        var { t } = this.props;
        var { Calendar } = this.state;

        if (!Calendar.Years.length) {
            return (
                <styles.CalendarContainer className="CalendarContainer">
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('task_status_progress_no_history')} TextAlign="left" />
                </styles.CalendarContainer>
            )
        }

        return (
            <styles.CalendarContainer className="CalendarContainer">
                {Calendar.Years.map((CalendarYear, CalendarYearIndex) => this.renderCalendarYear({ CalendarYear, CalendarYearIndex }))}
            </styles.CalendarContainer>
        );
    }

    renderCalendarDay = (CalendarDay, CalendarDayIndex) => {
        if (Object.keys(CalendarDay).length === 0) return <div key={CalendarDayIndex} />;

        var { t } = this.props;
        var { Amount, AssignedDate, CompletionStatus, GoalAmount, TaskType } = CalendarDay;

        TaskType = +TaskType;

        return (
            <styles.CalendarDayContainer className="CalendarDayContainer" key={CalendarDayIndex} TaskType={TaskType}>
                <styles.CalendarDayStatusContainer className="CalendarDayStatusContainer" CompletionStatus={CompletionStatus} />

                <styles.CalendarDayBodyContainer className="CalendarDayBodyContainer">
                    <PageText FontFamily="medium-italic" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={moment(AssignedDate).format('[WeeklyViewDayHeader]')} TextAlign="left" />
                    {
                        (TaskType === 2 || TaskType === 3) &&
                        <>
                            <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${Amount}`} TextAlign="left" />
                            <PageText FontFamily="medium-italic" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={`${TaskType === 2 ? t('min') : t('max')}: ${GoalAmount}`} TextAlign="left" />
                        </>
                    }
                </styles.CalendarDayBodyContainer>
            </styles.CalendarDayContainer>
        );
    }

    renderCalendarWeek = ({ CalendarWeek, CalendarWeekIndex, CalendarYearIndex }) => {
        var { t } = this.props;
        var { WeekNumber, Days } = CalendarWeek;
        var { TaskType } = this.state.Task;

        var { AvgAmount, Completed, CompletionPercentage, Total } = this.state.Stats.Years[CalendarYearIndex].Weeks[CalendarWeekIndex].Stats;

        return (
            <styles.CalendarWeekContainer className="CalendarWeekContainer" key={WeekNumber}>
                <styles.CalendarWeekColumn className="CalendarWeekColumn">
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('timeperiod_week_singular_first_letter_capital')} ${WeekNumber}`} TextAlign="left" />

                    <Spacer Size="extra-extra-small" />

                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('_completion')}:`} TextAlign="left" />
                    <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${CompletionPercentage}% (${Completed}/${Total})`} TextAlign="left" />

                    {
                        (TaskType === 2 || TaskType === 3) &&
                        <>
                            <Spacer Size="extra-extra-small" />

                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('avg')}:`} TextAlign="left" />
                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${AvgAmount}`} TextAlign="left" />
                        </>
                    }

                    
                </styles.CalendarWeekColumn>

                {Days.map((CalendarDay, index) => this.renderCalendarDay(CalendarDay, index))}
            </styles.CalendarWeekContainer>
        );
    }

    renderCalendarYear = ({ CalendarYear, CalendarYearIndex }) => {
        var { Year, Weeks } = CalendarYear;
        var { DayNames } = this.state.Calendar;

        return (
            <styles.CalendarYearContainer className="CalendarYearContainer" key={Year}>
                <styles.CalendarYearHeaderContainer className="CalendarYearHeaderContainer">
                    <styles.CalendarYearColumn className="CalendarYearColumn">
                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${Year}`} TextAlign="left" />
                    </styles.CalendarYearColumn>

                    {
                        DayNames.map(({ DayName }, DayNameIndex) => {
                            return (
                                <styles.CalendarYearColumn className="CalendarYearColumn" key={DayNameIndex}>
                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={DayName.substring(0,3)} TextAlign="left" />
                                </styles.CalendarYearColumn>
                            );
                        })
                    }
                </styles.CalendarYearHeaderContainer>

                <Spacer Size="medium" />

                <styles.CalendarYearWeeksContainer className="CalendarYearWeeksContainer">
                    {Weeks.map((CalendarWeek, CalendarWeekIndex) => this.renderCalendarWeek({ CalendarWeek, CalendarWeekIndex, CalendarYearIndex }))}
                </styles.CalendarYearWeeksContainer>
            </styles.CalendarYearContainer>
        )
    }

    renderHeader = () => {
        if (Object.keys(this.state.Task).length === 0) return null;

        var { t } = this.props;
        var { Icon, TaskName } = this.state.Task;

        return (
            <>
                <styles.HeaderContainer className="HeaderContainer">
                    <PageText FontFamily="semibold" FontSize="large" JustifyContent="flex-start" NoMargin Text={`${TaskName}`} TextAlign="left" />
                    <IconComponent src={IconsList[Icon]} />
                </styles.HeaderContainer>

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('task_status_progress_until_today')} TextAlign="left" />
            </>
        );
    }

    render() {
        if (!this.state.FirstLoad) return <Loading />;

        var { ClientDetails: { ClientId, DisplayName } } = this.props;
        var { Task } = this.state;

        var BreadCrumbs = [{ Name: `${DisplayName}`, LinkURL: `client/${ClientId}`, Props: { SelectedTab: 'Tasks' }, Search: '?tab=tasks' }];
        if (Object.keys(Task).length > 0) BreadCrumbs.push({ Name: Task.TaskName })

        return (
            <>
                <Page renderBreadcrumbs={BreadCrumbs}>
                    {this.props.TryingTasksAction && <Loading />}

                    {this.renderHeader()}

                    <Spacer Size="small" />

                    {this.renderCalendar()}
                </Page>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ClientDetails: state.Client.ClientDetails,

        TryingTasksAction: state.Tasks.TryingTasksAction,
        TryingTasksActionError: state.Tasks.TryingTasksActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearClientDetails, Tasks_GetUserTask_Progress } )(TaskProgressComponent));