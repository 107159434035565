import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddTrainingProgram, ClearTrainingPrograms, ClearSaveData, SaveDataInProps, SearchTrainingPrograms, UpdateSelectedTab } from '../../Services/Actions';
import moment from 'moment';
import history from '../../history';

import { AllFilters, SelectedDatesContainer, SelectedPreviewContainer, SelectedTemplateContainer, StyledDropdown, StyledLoadMore, StyledSearchInput } from './styles';

import AssignClients from '../../Components/AssignClients';
import Loading from '../../Components/Loading';
import MiniCalendar from '../../Components/Calendar/MiniCalendar';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import { TrainingProgramListItem } from '../../Components/Lists/ListItems';

import TrainingProgramModal from '../../Modals/TrainingProgram';

class AddTrainingProgramComponent extends React.Component {
    _isMounted = false;

    state = {
        ActiveSearch: false,
        CompletedFirstSearch: false,
        FilteredTemplateDays: [],
        LastSearchValue: '',
        Loading: true,
        NewSearch: false,
        PageNo: 1, PageSize: 48,
        SearchValue: '',
        SelectedDates: [],
        SelectedGroups: {},
        SelectedTemplateDays: null,
        SelectedTemplate: {},
        SelectedTemplateId: null,
        ShowTemplateDetailModal: false,
        StartDate: null,
        TemplateDetail_Preview: {}
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails, DayDate, GroupDetails, SelectedTemplate } = this.props;

        var StartDate = DayDate || null;
        var SelectedGroups = {};
        var SelectedUserIds = [];
        var SelectedUsers = [];
        var SelectedTemplate_State = { ...SelectedTemplate };
        var SelectedTemplateId = Number(SelectedTemplate.TrainingProgramId);
        var SelectedTemplateDays = SelectedTemplate.TemplateDays;

        if (Object.entries(ClientDetails).length !== 0) {
            SelectedUserIds = [ Number(ClientDetails.ClientId) ];
            SelectedUsers = [{ ...ClientDetails, UserId: Number(ClientDetails.ClientId) }];
        }

        if (Object.entries(GroupDetails).length !== 0) {
            var { GroupId, GroupImage, GroupName, GroupType } = GroupDetails;
            SelectedGroups[`${GroupType}_GroupId-${GroupId}`] = { AllMembers: true, GroupImage, GroupName, GroupType, Members: [], RemoveMembers: [] };
        }

        this.setState({ SelectedGroups, SelectedTemplateDays, SelectedTemplate: SelectedTemplate_State, SelectedTemplateId, SelectedUserIds, SelectedUsers, StartDate }, () => this.onLoadPrograms());
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearSaveData();
        this.props.ClearTrainingPrograms();
    }

    onAddTrainingProgram = () => {
        var { SelectedGroups, SelectedUserIds, StartDate, SelectedTemplateId } = this.state;

        var ClientIds = SelectedUserIds.join(',');

        this.props.AddTrainingProgram({ ClientIds, Groups: SelectedGroups, StartDate, TrainingProgramId: SelectedTemplateId }).then(() => {
            if (this._isMounted && !this.props.TryingAddTrainingProgramError) {
                if (this.props.location.state && this.props.location.state.from) history.goBack();
                else this.props.SaveDataInProps({ LibraryMenu: 'Workouts', LibrarySubmenu: 'TrainingPrograms'}).then(() => history.push('/library'));
            }
        });
    }

    onAssignTemplateToClients = TemplateDetail => {
        this.onSelectTemplate(TemplateDetail);
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchTrainingPrograms) {
            var { SearchValue, LastSearchValue } = this.state;

            if (SearchValue !== LastSearchValue) {
                this.setState({ PageNo: 1, PageSize: 48, LastSearchValue: SearchValue }, () => {
                    this.onLoadPrograms();
                });
            }
        }
    }

    onLoadPrograms = Increment => {
        var { FilteredTemplateDays, NewSearch, PageNo, PageSize, SearchValue } = this.state;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else NewSearch = true;

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.SearchTrainingPrograms({ AddToExisting: Increment, PageNo, PageSize, TemplateDays: FilteredTemplateDays, ProgramName: SearchValue }).then(() => this._isMounted ? this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false }) : null));
    }

    onSelectClients = ({ SelectedClients: SelectedUsers, SelectedGroups }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ SelectedGroups, SelectedUsers, SelectedUserIds: SelectedUserIds_New });
    }

    onSelectDate = DayDate => {
        var { SelectedTemplateId, StartDate } = this.state;

        if (SelectedTemplateId && DayDate !== StartDate) {
            var SelectedDates = [];

            for (var i = 0; i < this.state.SelectedTemplateDays; i++) {
                SelectedDates.push(moment(DayDate).add(i, 'days').format('YYYY-MM-DD'));
            }

            this.setState({ SelectedDates, StartDate: DayDate });
        }
    }

    onSelectTemplate = SelectedTemplate => {
        var { SelectedTemplateId, StartDate } = this.state;

        var { TemplateDays, TrainingProgramId } = SelectedTemplate;

        if (Number(SelectedTemplateId) !== Number(TrainingProgramId)) {
            var SelectedDates = [];
            StartDate = StartDate || moment();

            for (var i = 0; i < TemplateDays; i++) {
                SelectedDates.push(moment(StartDate).add(i, 'days').format('YYYY-MM-DD'));
            }

            this.setState({ SelectedDates, SelectedTemplateDays: TemplateDays, SelectedTemplate, SelectedTemplateId: TrainingProgramId, StartDate });
        }
    }

    onSelectTemplateDay = (event, TemplateDayId, TemplateDayIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = TemplateDayIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    FilteredTemplateDays: [ ...prevState.FilteredTemplateDays, ...[ TemplateDayId ] ],
                }
            }, () => this.onLoadPrograms());
        } else {
            this.setState((prevState) => {
                return {
                    FilteredTemplateDays: [ ...prevState.FilteredTemplateDays.slice(0, TemplateDayIndex), ...prevState.FilteredTemplateDays.slice(TemplateDayIndex + 1) ],
                }
            }, () => this.onLoadPrograms());
        }
    }

    onToggleShowTemplateDetailModal = (ShowTemplateDetailModal, TemplateDetail_Preview, TrainingProgramId) => {
        var { SelectedTemplate, SelectedTemplateId } = this.state;

        if (TrainingProgramId && Number(TrainingProgramId) === Number(SelectedTemplateId)) { SelectedTemplate = {}; SelectedTemplateId = null; }
        
        this.setState({ SelectedTemplate, SelectedTemplateId, ShowTemplateDetailModal, TemplateDetail_Preview });
    }

    renderFilters = () => {
        var { t } = this.props;
        var { TrainingProgramsFilterSettings: { TemplateDays }, TryingSearchTrainingPrograms } = this.props;
        var { FilteredTemplateDays, SearchValue } = this.state;

        return (
            <AllFilters>
                <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                />

                <StyledDropdown
                    Disabled={TryingSearchTrainingPrograms}
                    ItemId="TemplateDayId"
                    ItemName="TemplateDay"
                    Items={TemplateDays}
                    Loading={false}
                    NeedsToLoad={false}
                    OnSelectItem={(event, TemplateDayId, TemplateDayIndex) => this.onSelectTemplateDay(event, TemplateDayId, TemplateDayIndex)}
                    SelectedItemIds={FilteredTemplateDays}
                    Title={t('template_days')}
                />
            </AllFilters>
        );
    }

    renderSelectedPreview = () => {
        var { t } = this.props;
        var { SelectedDates, SelectedTemplate, SelectedTemplateId } = this.state;

        return (
            <SelectedPreviewContainer className="SelectedPreviewContainer">
                <SelectedTemplateContainer className="SelectedTemplateContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('WorkoutPlanSettings_selected')} TextAlign="center" />
                    <Spacer Size="small" />
                    {
                        !!+SelectedTemplateId &&
                        <TrainingProgramListItem
                            HideSelect
                            Item={SelectedTemplate}
                            SelectTemplate={() => null}
                            SelectedItemIds={[]}
                            ViewTemplate={() => this.onToggleShowTemplateDetailModal(true, SelectedTemplate)}
                        />
                    }
                </SelectedTemplateContainer>
                <SelectedDatesContainer className="SelectedDatesContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('choose_dates')} TextAlign="center" />
                    
                    <Spacer Size="extra-small" />
                    
                    <MiniCalendar
                        Disabled={!SelectedTemplateId}
                        DisabledText={t('select_template_first')}
                        OnSelectDate={DayDate => this.onSelectDate(DayDate)}
                        SelectedDates={SelectedDates}
                    />
                </SelectedDatesContainer>
            </SelectedPreviewContainer>
        );
    }

    renderTemplateDetailModal = () => {
        var { ShowTemplateDetailModal, TemplateDetail_Preview } = this.state;

        if (ShowTemplateDetailModal) {
            return (
                <TrainingProgramModal
                    OnAssignClients={() => this.onAssignTemplateToClients({ ...TemplateDetail_Preview })}
                    OnHide={TrainingProgramId => this.onToggleShowTemplateDetailModal(false, null, TrainingProgramId)}
                    OnLoadPrograms={this.onLoadPrograms}
                    TemplateDetail_Preview={TemplateDetail_Preview}
                    Show={ShowTemplateDetailModal}
                />
            );
        }
    }

    renderTemplatesList = () => {
        var { t } = this.props;
        var { TrainingProgramsList, TrainingProgramsList_TotalRecords, IsMoreTrainingPrograms, TryingSearchTrainingPrograms } = this.props;
        var { ActiveSearch, CompletedFirstSearch, NewSearch, PageNo, PageSize, SelectedTemplateId } = this.state;

        var SelectedItemIds = SelectedTemplateId ? [ Number(SelectedTemplateId) ] : [];

        return (
            <StyledLoadMore
                ActiveSearch={ActiveSearch}
                CompletedFirstSearch={CompletedFirstSearch}
                IsLoading={TryingSearchTrainingPrograms}
                ItemDescriptionType={t('templates_plural').toLowerCase()}
                ItemId="TrainingProgramId"
                ItemName="ProgramName"
                ItemProps={{
                    SelectTemplate: Template => this.onSelectTemplate(Template),
                    ShowDescription: true,
                    ViewTemplate: Template => this.onToggleShowTemplateDetailModal(true, Template)
                }}
                ItemRenderer={TrainingProgramListItem}
                Items={TrainingProgramsList}
                HasMoreItems={IsMoreTrainingPrograms}
                LoadMore={() => this.onLoadPrograms(true)}
                NewSearch={NewSearch}
                NoItemsText={t('search_noresults')}
                PageNo={PageNo}
                PageSize={PageSize}
                SelectedItemIds={SelectedItemIds}
                TotalRecords={TrainingProgramsList_TotalRecords}
            />
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { Device, TryingAddTrainingProgram } = this.props;
        var { SelectedGroups, StartDate, SelectedTemplateId, SelectedUserIds, SelectedUsers } = this.state;

        var Breadcrumbs = [{ Name: t('add_workouts_from_training_program') }];
        var SubmitButtonProps = {
            Disabled: !StartDate || !SelectedTemplateId || (!Object.entries(SelectedGroups).length && !SelectedUserIds.length),
            OnClick: this.onAddTrainingProgram,
            Title: t('add_workouts_from_training_program'),
        }

        return (
            <>
                {TryingAddTrainingProgram ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('add_workouts_from_training_program')} />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'medium' : 'small'} />

                    {this.renderFilters()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    <AssignClients
                        EligibleTypes="Both"
                        GetStartedEligible="Workouts"
                        HorizontalScroll={false}
                        OnSelectClients={Users => this.onSelectClients(Users)}
                        SelectedGroups={SelectedGroups}
                        SelectedUserIds={SelectedUserIds}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    {this.renderTemplatesList()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    {this.renderSelectedPreview()}
                </Page>

                {this.renderTemplateDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,
        GroupDetails: state.SaveData.GroupDetails,
        SelectedTemplate: state.SaveData.SelectedTemplate,

        TrainingProgramsFilterSettings: state.Templates.TrainingProgramsFilterSettings,
        IsMoreTrainingPrograms: state.Templates.IsMoreTrainingPrograms,
        TrainingProgramsList: state.Templates.TrainingProgramsList,
        TrainingProgramsList_TotalRecords: state.Templates.TrainingProgramsList_TotalRecords,

        TryingAddTrainingProgram: state.Templates.TryingAddTrainingProgram,
        TryingAddTrainingProgramError: state.Templates.TryingAddTrainingProgramError,
        TryingSearchTrainingPrograms: state.Templates.TryingSearchTrainingPrograms,
        TryingSearchTrainingProgramsError: state.Templates.TryingSearchTrainingProgramsError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddTrainingProgram, ClearTrainingPrograms, ClearSaveData, SaveDataInProps, SearchTrainingPrograms, UpdateSelectedTab } )(AddTrainingProgramComponent));